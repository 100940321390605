import { css, keyframes } from 'styled-components'

const CARD_KEY_FRAME_DURATION = '0.25s'

const cardAnimation = keyframes`
    to {
        transform: rotate(-2deg);
    }
`

export const cssCardAnimation = css`
    animation-name: ${cardAnimation};
    animation-duration: ${CARD_KEY_FRAME_DURATION};
    animation-direction: alternate;
    animation-iteration-count: 4;
    animation-timing-function: cubic-bezier(0.4, -0.42, 0.6, 1.2);
    :hover {
        animation-name: ${cardAnimation};
        animation-duration: ${CARD_KEY_FRAME_DURATION};
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(0.4, -0.42, 0.6, 1.2);
    }
`

import React from 'react'

import Image from 'components/Image'
import { LegacyLink as Link } from 'components/Link'
import Box from 'components/Primitives/Box'
import type { ProgressStepItem } from 'components/ProgressBar/ProgressBar'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import Separator from 'components/Separator'
import finimizeLogoPrimaryFull from 'public/images/finimize-primary-logo-full.svg'
import { routeMap } from 'shared/routes'

type Props = {
    currentStep: Step
    children?: React.ReactNode
}

// generates the step types from steps
export type Step = typeof steps[number]

// Define steps for progress bar

// These define the name of the step that will appear in the progress bar
export const ACCOUNT_PAGE = 'Account'
export const PAYMENT_PAGE = 'Payment'

// The order of the array dictates the order in the progress bar
const steps = [ACCOUNT_PAGE, PAYMENT_PAGE] as const

const CheckoutFormProgressContainer = (props: Props) => {
    const { currentStep, children } = props

    // Generates the ProgressStepItem object required by the progress bar
    const _steps: ProgressStepItem[] = steps.map((step: Step, index: number) => ({
        step: index,
        id: step,
        label: step,
    }))

    const _currentStep = _steps.filter(step => step.id === currentStep).pop()

    return (
        <Box width="100%" paddingBottom={[6, 6, 6, 4]} paddingX={2}>
            <Box
                m={0}
                display={'flex'}
                flexDirection={'column'}
                alignItems="start"
                paddingBottom={1}
                paddingTop={2}
            >
                <Link url={routeMap.home}>
                    <Image
                        mb="2rem"
                        alt="Finimize Logo"
                        src={finimizeLogoPrimaryFull}
                        width={['200px', '320px']}
                    />
                </Link>

                <ProgressBar items={_steps} currentStep={_currentStep}></ProgressBar>
            </Box>
            <Separator></Separator>
            <Box pt={1}>{children}</Box>
        </Box>
    )
}

export default CheckoutFormProgressContainer

import React, { useMemo } from 'react'

import ProgressStep from './ProgressStep'

import Icon from 'components/Icon'
import Set from 'components/Set'

export interface ProgressStepItem {
    id: string
    label: string
    step: number
}

type Props = {
    items: ProgressStepItem[]
    spacing?: number
    currentStep?: ProgressStepItem
}

const ProgressBar = (props: Props) => {
    const { items, spacing = 1, currentStep } = props
    const selected = currentStep || items[0]
    const arrLength = items.length - 1
    const progressSteps = useMemo(
        () =>
            items.map((item, index) => {
                const isSelected = selected.step === item.step
                const stepCompleted = item.step < selected.step

                return (
                    <div key={item.label} style={{ display: 'flex' }}>
                        <ProgressStep
                            item={item}
                            isSelected={isSelected}
                            stepCompleted={stepCompleted}
                        />
                        {index !== arrLength && (
                            <Icon icon="RiArrowRightSLine" color="grey800" marginLeft={1} />
                        )}
                    </div>
                )
            }),
        [items],
    )
    return (
        <Set spacing={spacing} justifyContent="center">
            {progressSteps}
        </Set>
    )
}

export default ProgressBar

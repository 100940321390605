import Box from 'components/Primitives/Box'

const DiscountBanner = ({
    discountPct,
    discountAmount,
    displayedDiscountAmount,
    promotionTrialDays,
}: {
    discountPct: number | null
    discountAmount: number | null
    displayedDiscountAmount: string | null
    promotionTrialDays: number | null
}): JSX.Element | null => {
    let text = null

    if (discountPct && promotionTrialDays)
        text = `${promotionTrialDays} Day Free Trial + ${discountPct}% Off`
    else if (promotionTrialDays && discountAmount)
        text = `${promotionTrialDays} Day Free Trial + ${displayedDiscountAmount} Off`
    else if (discountPct) text = `${discountPct}% discount applied`
    else if (discountAmount) text = `${displayedDiscountAmount} discount applied`
    else if (promotionTrialDays) text = `${promotionTrialDays} Day Free Trial`

    return text ? (
        <Box
            mt="0.8rem"
            bg="warning.300"
            p="8px"
            width="180px"
            fontSize="12px"
            textAlign="center"
            borderRadius="small"
            color="warning.800"
            fontWeight={'600'}
        >
            {text}
        </Box>
    ) : null
}

export default DiscountBanner

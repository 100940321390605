import { format } from 'date-fns'
import { css, type RuleSet } from 'styled-components'

import Image from 'components/Image'
import Paragraph from 'components/Paragraph'
import Box from 'components/Primitives/Box'
import Set from 'components/Set'
// import finimizeDarkIcon from 'public/images/finimize-dark-icon.png'
import finimizeBlackFridayIcon from 'public/images/bf-logo.png'
import { novaMono, radii } from 'theme'

type Props = {
    // we specify name here in case
    // we want to include future functionality
    // i.e. name changes to user input
    name?: string

    // for animation component experiment
    cssAnimation?: RuleSet<object>
}

// Card config
const CARD_ASPECT_RATIO = 1.35
const CARD_WIDTH = 20 // vw
const CARD_MIN_WIDTH = 250 // px
const CARD_MAX_WIDTH = 300 // px
export const CARD_BORDER_RADIUS = radii['large'] // Card border-radius

const randomRefCode = '009ref457'

const MembershipCard = ({ name, cssAnimation }: Props) => {
    const getCurrentDate = format(Date.now(), 'dd.MM.yy')

    return (
        <Box
            width={`${CARD_WIDTH}vw`}
            height={`${CARD_ASPECT_RATIO * CARD_WIDTH}vw`}
            minWidth={`${CARD_MIN_WIDTH}px`}
            minHeight={`${CARD_ASPECT_RATIO * CARD_MIN_WIDTH}px`}
            maxWidth={`${CARD_MAX_WIDTH}px`}
            maxHeight={`${CARD_ASPECT_RATIO * CARD_MAX_WIDTH}px`}
            borderRadius={CARD_BORDER_RADIUS}
            bg="neutral.999"
            padding={2}
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            fontFamily={novaMono.style.fontFamily}
            boxShadow="0px 12px 20px rgba(0, 0, 0, 0.2)"
            css={cssAnimation}
        >
            <Image
                alignSelf="center"
                src={finimizeBlackFridayIcon}
                alt="Finimize App"
                width="120px"
                pt={2}
            />

            <Box>
                <Paragraph
                    opacity={0.4}
                    color="white"
                    fontSize="21px"
                    css={css`
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    `}
                >
                    {name || 'BOBBY AXELROD'}
                </Paragraph>

                <Set justifyContent={'space-between'} fontSize="15px" color={'white'}>
                    <Box>{randomRefCode}</Box>
                    <Box>{getCurrentDate}</Box>
                </Set>
            </Box>
        </Box>
    )
}

export default MembershipCard

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PlansCheckoutDetail_viewer = {
    readonly me: {
        readonly firstName: string | null;
    } | null;
    readonly webPaymentPlan: {
        readonly id: string;
        readonly amount: number;
        readonly currency: string;
        readonly currencySymbol: string;
        readonly interval: string;
        readonly nickname: string;
        readonly promotionApplied: {
            readonly trialDays: number | null;
            readonly stripeDiscount: {
                readonly discountPct: number | null;
                readonly discountAmount: number | null;
                readonly couponDurationInMonths: number | null;
            };
        } | null;
    };
    readonly " $fragmentRefs": FragmentRefs<"Experiments_viewer">;
    readonly " $refType": "PlansCheckoutDetail_viewer";
};
export type PlansCheckoutDetail_viewer$data = PlansCheckoutDetail_viewer;
export type PlansCheckoutDetail_viewer$key = {
    readonly " $data"?: PlansCheckoutDetail_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PlansCheckoutDetail_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "slug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlansCheckoutDetail_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "productSlug",
          "variableName": "slug"
        }
      ],
      "concreteType": "WebPaymentPlan",
      "kind": "LinkedField",
      "name": "webPaymentPlan",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currencySymbol",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "interval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nickname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "slug",
              "variableName": "slug"
            }
          ],
          "concreteType": "DiscountPageConfig",
          "kind": "LinkedField",
          "name": "promotionApplied",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "trialDays",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "StripeDiscount",
              "kind": "LinkedField",
              "name": "stripeDiscount",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "discountPct",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "discountAmount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "couponDurationInMonths",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Experiments_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = 'fc2a446ce2c362c4c5e055128bcc8bb8';
export default node;

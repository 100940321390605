import React from 'react'

import { css } from 'styled-components'

import type { ProgressStepItem } from './ProgressBar'

import Box from 'components/Primitives/Box'
import { fonts } from 'theme'

type Props = {
    item: ProgressStepItem
    isSelected: boolean
    stepCompleted: boolean
}

const ProgressStep = (props: Props) => {
    const { isSelected, item, stepCompleted } = props
    return (
        <Box
            key={item.label}
            id={item.id}
            css={
                isSelected
                    ? styles.highlightedText
                    : stepCompleted
                    ? styles.previouslyHighlightedText
                    : styles.text
            }
            fontSize="16px"
        >
            {item.label}
        </Box>
    )
}

const styles = {
    previouslyHighlightedText: css`
        font-weight: ${fonts.weight.bold};
        color: #2f80ed;
    `,
    highlightedText: css`
        font-weight: ${fonts.weight.bold};
        color: black;
    `,
    text: css`
        font-weight: ${fonts.weight.medium};
        color: #718096;
    `,
}

export default ProgressStep

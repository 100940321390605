/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PlanLayout_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"PlansCheckoutDetail_viewer">;
    readonly " $refType": "PlanLayout_viewer";
};
export type PlanLayout_viewer$data = PlanLayout_viewer;
export type PlanLayout_viewer$key = {
    readonly " $data"?: PlanLayout_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PlanLayout_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "slug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlanLayout_viewer",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "slug"
        }
      ],
      "kind": "FragmentSpread",
      "name": "PlansCheckoutDetail_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = '3660378d88e8225b06adda5e378231c8';
export default node;

import type { ChangeEvent } from 'react';
import { useState, useCallback } from 'react'

import constate from 'constate'

// Creating a custom hook that returns name to be displayed on card and
// callback to change the name state.

const useMembershipCardName = (): {
    membershipCardName: string
    handleMembershipCardNameChange: (event: ChangeEvent<HTMLInputElement>) => void
} => {
    const [membershipCardName, setMembershipCardName] = useState('')

    const handleMembershipCardNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setMembershipCardName(event.target.value.toUpperCase())
    }, [])

    return { membershipCardName, handleMembershipCardNameChange }
}

// Wrapping hook with the constate factory, this returns the provider
// to wrap around the root of our layout, hook to return the current name state,
// and hook to return callback to call on in order to modify name state

export const [MembershipCardNameProvider, getSignUpNameFieldValue, useHandleFirstNameChange] =
    constate(
        useMembershipCardName,
        value => value.membershipCardName,
        value => value.handleMembershipCardNameChange,
    )
